@import '@spotahome/marketplace-common/src/scss/base';

.top-homecards {
  background-color: transparent;
  margin: 0 auto;
  padding: $spacing-xl $spacing-m;
  
  @include max-container-homepage;
  
  @include from-laptop {
    padding: 120px $spacing-l 0;
  }

  &__title {
    text-align: center;
    font-size: $font-xxxxl;
    font-family: $font-title;
    margin: $spacing-l 0;

    @include from-laptop {
      display: none;
    }
  }

  &__list {
    display: flex;
    overflow-x: auto;
    margin: 0;
    padding: $spacing-m 0;

    @include from-laptop {
      display: grid; 
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, auto); 
      gap: $spacing-l; 
      overflow-x: hidden;
    }


    &-item {
      border-radius: 0;
      display: inline-block;
      margin-right: 3 * $spacing-xs;
      min-width: 236px;

      @include only-mobile {
        min-width: 269px;
      }

      @include from-tablet {
        min-width: 256px;
      }

      @include from-laptop {
        margin: 0;
        min-width: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
